import { KeyboardArrowLeft } from '@mui/icons-material';
import PollIcon from '@mui/icons-material/Poll';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import DocumentIcon from 'assets/img/dashboard/documentCheckIcon.svg';
import ReportIcon from 'assets/img/dashboard/reportIcon.svg';
import UpgradeIcon from 'assets/img/dashboard/upgradeIcon.svg';
import AppButton from 'components/new_components/AppButton';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GetAllSurvey } from 'store/actions/adminActions';
import { GetAllArticleHeader } from 'store/actions/articlesActions';
import {
    GetCiiSectorSummaries,
    GetContinuityGroupMetrics,
    GetCriteriaSummaries,
} from 'store/actions/complianceActions';
import { GetAllSurveyResponse } from 'store/actions/merchantActions';
import { isFullFunctionPlan, pdfWithPrintJs, removeSpaces } from 'utils';
import SurveyIcon from '../../assets/img/SurveyIcon.png';
import CheckDone from '../../assets/img/dashboard/CheckDone.svg';
import CheckWarning from '../../assets/img/dashboard/CheckWarning.svg';
import {
    GetDashboardCompliance,
    GetMerchantCiiGeneralSummary,
    GetMerchantComplianceSummary,
    GetMerchantContinuityGeneralSummary,
    GetMerchantGDPAGeneralSummary,
    GetMerchantGDPASummaryByCategory,
    GetMerchantISO9001SummaryByManagement,
    GetMerchantISO9901CGeneralSummary,
    GetMerchantIsoGeneralSummary,
    GetMerchantIsoSummaryByAnnex,
    GetMerchantIsoTwoGeneralSummary,
    GetMerchantIsoTwoSummaryByAnnex,
    GetMerchantKDPAGeneralSummary,
    GetMerchantKDPASummaryByCategory,
    GetMerchantNDPRGeneralSummary,
    GetMerchantNDPRSummaryByCategory,
    GetMerchantOverviewSummary,
    GetMerchantPCISLCGeneralSummary,
    GetMerchantPCISLCSummaryByControl,
    GetMerchantPcidss4point0ComplianceSummary,
    GetMerchantPcidss4point0OverviewSummary,
    GetMerchantSoc2GeneralSummary,
    GetMerchantSoc2SummaryByControl,
    GetMerchantTechSecGeneralSummary,
    GetMerchantTechSecSummaryByTechSec,
    // GetSmartComplianceAIRecommendations,
    GetSmartComplianceReport,
} from '../../store/actions/dashboardActions';
import { GetCompanyDetails } from '../../store/actions/merchantActions';
import PageHeader from '../new_components/PageHeader';
import FourthLayerReport from './AllReport/FourthLayerReport';
import SecondLayerReport from './AllReport/SecondLayerReport';
import ThirdLayerReport from './AllReport/ThirdLayerReport';
import CisoReport from './CisoReport/CisoReport';
import InHouseAuditModal from './InHouseAuditModal';
import BLOCKCHAINIndex from './OtherReports/BLOCKCHAIN';
import Cii from './OtherReports/Cii';
import ISOIndex from './OtherReports/ISO';
import CONTINUITYIndex from './OtherReports/ISO22301';
import ISOTWOIndex from './OtherReports/ISO27001-2022';
import NDPRIndex from './OtherReports/NDPR';
import PCIDSSIndex from './OtherReports/PCIDSS';
import PCIDSS4Point0Index from './OtherReports/PCIDSS4.0';
import SOCIndex from './OtherReports/SOC';
import Summary from './SummaryReport/Index';
import TopDashboardReport from './TopDashboardReport';
//userguide
import { Tour } from 'antd';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
//translations
import { useTranslation } from 'react-i18next';
import GDPAIndex from './OtherReports/GDPA';
import ISO27001plusIndex from './OtherReports/ISO27001+ISO27035';
import KDPAIndex from './OtherReports/KDPA';
import PCISLC from './OtherReports/PCISLC';
import ISO9001 from './OtherReports/ISO9001';
import SmartDashboardAI from './smartDashboardAI';

const DashboardIndex = (props) => {
    const {
        GetDashboardCompliance,
        GetMerchantNDPRGeneralSummary,
        GetMerchantKDPAGeneralSummary,
        GetMerchantGDPAGeneralSummary,
        GetMerchantNDPRSummaryByCategory,
        GetMerchantPCISLCSummaryByControl,
        GetMerchantKDPASummaryByCategory,
        GetMerchantGDPASummaryByCategory,
        GetMerchantSoc2GeneralSummary,
        GetMerchantSoc2SummaryByControl,
        GetMerchantOverviewSummary,
        GetMerchantComplianceSummary,
        GetMerchantIsoGeneralSummary,
        GetMerchantIsoTwoGeneralSummary,
        GetMerchantContinuityGeneralSummary,
        GetMerchantIsoSummaryByAnnex,
        GetMerchantIsoTwoSummaryByAnnex,
        GetMerchantPcidss4point0OverviewSummary,
        GetMerchantPcidss4point0ComplianceSummary,
        GetCompanyDetails,
        GetSmartComplianceReport,
        // GetSmartComplianceAIRecommendations,
        GetMerchantPCISLCGeneralSummary,
        GetMerchantISO9901CGeneralSummary,
        GetMerchantISO9001SummaryByManagement,
        dashboardResult,
        ndprSummary,
        ndprGeneral,
        gdpaSummary,
        gdpaGeneral,
        kdpaSummary,
        kdpaGeneral,
        socGeneral,
        socSummary,
        pciGeneral,
        pciSummary,
        isoGeneral,
        isoSummary,
        isotwoGeneral,
        isotwoSummary,
        techSecGeneral,
        techSecSummary,
        pcidss4Point0General,
        pcidss4Point0Summary,
        pricing_plan,
        organization,
        merchant_info,
        GetCriteriaSummaries,
        GetCiiSectorSummaries,
        GetContinuityGroupMetrics,
        all_criteria_summary,
        GetAllSurvey,
        GetAllSurveyResponse,
        all_survey,
        survey_responses,
        continuitySummary,
        continuityGeneral,
        smartComplianceReport,
        ai_recommendations,
        userguide_info,
        GetMerchantCiiGeneralSummary,
        ciiGeneral,
        pcislc_general_summary,
        pcislc_summary_by_control,
        iso9001_general_summary,
        iso9001_summary_by_control,
    } = props;

    const theme = useTheme();
    //translation
    const { t } = useTranslation('overview');
    const router = useHistory();
    const dispatch = useDispatch();
    // states
    const [indexValue, setIndexValue] = useState(1);
    const [view, setView] = useState('dashboard');
    const [openSummary, setOpenSummary] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isDropDown, setIsDropDown] = useState(false);
    const [aIModalOpen, setAIModalOpen] = useState(false);
    const [suggestionsModalOpen, setSuggestionsModalOpen] = useState(false);
    const [openTour, setOpenTour] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);
    const [isLeftDisabled, setIsLeftDisabled] = useState(true);
    const [isRightDisabled, setIsRightDisabled] = useState(false);

    const scrollContainerRef = useRef(null);

    const updateButtonState = () => {
        if (scrollContainerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
            setIsLeftDisabled(scrollLeft === 0);
            setIsRightDisabled(scrollLeft + clientWidth === scrollWidth);
        }
    };

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.addEventListener('scroll', updateButtonState);
        }
        return () => {
            if (scrollContainerRef.current) {
                scrollContainerRef.current.removeEventListener('scroll', updateButtonState);
            }
        };
    }, []);

    const handleOpenSummary = () => {
        setOpenSummary(true);
    };

    const handleCloseSummary = () => {
        setOpenSummary(false);
    };
    // memos
    const standards = useMemo(() => {
        const paidStandards = removeSpaces(organization?.paid_standards)?.toLowerCase();
        const standards = removeSpaces(organization?.standards)?.toLowerCase();
        return standards?.split(',')?.filter((standard) => paidStandards?.includes(standard));
    }, [organization]);

    // constants
    const tabList = [
        {
            id: 2,
            name: 'ndpr',
            image: dashboardResult?.ndpr_percentage !== 100 ? CheckWarning : CheckDone,
            // plan: ['main'],
        },
        {
            id: 3,
            name: 'soc2',
            image: dashboardResult?.soc2_percentage !== 100 ? CheckWarning : CheckDone,
            // plan: ['main'],
        },
        {
            id: 4,
            name: 'pcidss',
            image: dashboardResult?.pci_percentage !== 100 ? CheckWarning : CheckDone,
            // plan: ['free', 'main'],
        },
        {
            id: 5,
            name: 'iso27001',
            image: dashboardResult?.iso_percentage !== 100 ? CheckWarning : CheckDone,
            // plan: ['main'],
        },

        {
            id: 6,
            name: 'blockchain',
            image: dashboardResult?.blockchain_percentage !== 100 ? CheckWarning : CheckDone,
            // plan: ['main'],
        },
        {
            id: 7,
            name: 'iso22301',
            image: dashboardResult?.continuity_percentage !== 100 ? CheckWarning : CheckDone,
        },
        {
            id: 8,
            name: 'pcidss4.0',
            image: dashboardResult?.card4_percentage !== 100 ? CheckWarning : CheckDone,
        },
        {
            id: 9,
            name: 'kdpa',
            image: dashboardResult?.kdpa_percentage !== 100 ? CheckWarning : CheckDone,
        },
        {
            id: 10,
            name: 'gdpa',
            image: dashboardResult?.gdpa_percentage !== 100 ? CheckWarning : CheckDone,
        },
        {
            id: 11,
            name: 'cii',
            image: dashboardResult?.cii_percentage !== 100 ? CheckWarning : CheckDone,
        },
        {
            id: 12,
            name: 'iso27001-2022',
            image: dashboardResult?.iso_two_percentage !== 100 ? CheckWarning : CheckDone,
        },
        {
            id: 13,
            name: 'iso27017+iso27032',
            image: dashboardResult?.iso_two_percentage !== 100 ? CheckWarning : CheckDone,
        },
        {
            id: 14,
            name: 'pcislc',
            image: dashboardResult?.sslc_percentage !== 100 ? CheckWarning : CheckDone,
        },
        {
            id: 15,
            name: 'iso9001',
            image: dashboardResult?.iso9001_percentage !== 100 ? CheckWarning : CheckDone,
        },
    ];

    const accessTab = tabList.filter((res) => {
        return standards?.includes(res?.name);
    });

    const tabFunctions = {
        2: [GetMerchantNDPRGeneralSummary, GetMerchantNDPRSummaryByCategory],
        3: [GetMerchantSoc2GeneralSummary, GetMerchantSoc2SummaryByControl],
        4: [GetMerchantOverviewSummary, GetMerchantComplianceSummary],
        5: [GetMerchantIsoGeneralSummary, GetMerchantIsoSummaryByAnnex],
        6: [GetCriteriaSummaries],
        7: [GetMerchantContinuityGeneralSummary, GetContinuityGroupMetrics],
        8: [GetMerchantPcidss4point0OverviewSummary, GetMerchantPcidss4point0ComplianceSummary],
        9: [GetMerchantKDPAGeneralSummary, GetMerchantKDPASummaryByCategory],
        10: [GetMerchantGDPAGeneralSummary, GetMerchantGDPASummaryByCategory],
        11: [GetCiiSectorSummaries, GetMerchantCiiGeneralSummary],
        12: [GetMerchantIsoTwoGeneralSummary, GetMerchantIsoTwoSummaryByAnnex],
        13: [GetMerchantTechSecGeneralSummary, GetMerchantTechSecSummaryByTechSec],
        14: [GetMerchantPCISLCGeneralSummary, GetMerchantPCISLCSummaryByControl],
        15: [GetMerchantISO9901CGeneralSummary, GetMerchantISO9001SummaryByManagement],
    };
    const onChangeTab = async (val) => {
        setIndexValue(val);
        const functions = tabFunctions[val];

        if (functions) {
            for (const func of functions) {
                try {
                    await dispatch(func());
                } catch (error) {
                    console.error(`Error executing tab switch data fetching function: ${func}`, error);
                }
            }
        }
    };
    const onChangeView = () => {
        setView('ciso-report');
    };

    const openAIModal = () => {
        setAIModalOpen(true);
    };
    const closeAIModal = () => {
        setAIModalOpen(false);
    };
    const openSuggestionsModal = () => {
        setSuggestionsModalOpen(true);
    };
    const closeSuggestionsModal = () => {
        setSuggestionsModalOpen(false);
    };

    //article
    useEffect(() => {
        getAllArticleHeader();
    }, []);

    // functions
    const getAllArticleHeader = async () => {
        setLoading({ ...loading, content: true });
        const res = await dispatch(GetAllArticleHeader());
        if (!res.success) {
            toast.error(res?.message);
        }
        setLoading({ ...loading, content: false });
    };

    useEffect(() => {
        const fetchDetails = async () => {
            await GetDashboardCompliance();
        };
        const CompanyDetails = async () => {
            await GetCompanyDetails();
        };
        fetchDetails();
        CompanyDetails();
    }, []);

    const getAllSurvey = async () => {
        setLoading(true);
        const res = await GetAllSurvey();
        setLoading(false);
        if (!res.success) {
            toast.error(res?.message);
        }
        setLoading(false);
    };

    // async functions
    const getAllSurveyResponse = async () => {
        const res = await GetAllSurveyResponse('');
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };
    const getSmartComplianceReport = async () => {
        const res = await GetSmartComplianceReport();
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };
    // const getSmartComplianceAIRecommendations = async () => {
    //     const res = await GetSmartComplianceAIRecommendations();
    //     if (!res?.success) {
    //         toast.error('Something went wrong!');
    //         console.log(res?.message);
    //     }
    // };

    useEffect(() => {
        getAllSurvey();
        getAllSurveyResponse();
        getSmartComplianceReport();
        // getSmartComplianceAIRecommendations();
    }, []);

    // Diffrence in month
    const diffrenceInMonths = (current, start) => {
        var timeDiff = Math.abs(current.getTime() - start.getTime());
        return Math.round(timeDiff / (2e3 * 3600 * 365.25));
    };

    const diffrenceResult = diffrenceInMonths(new Date(), new Date(merchant_info?.date_created));

    const freePlanTab = tabList.filter((tab) => {
        return tab.name === 'ndpr';
    });

    const openAuditModal = () => {
        setIsOpen(true);
    };

    const gotoPlanSettings = () => {
        router.push('/merchant/settings', { tab_: 2 });
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const dropDownRef = useRef(null);
    useEffect(() => {
        const dropEffectClick = (e) => {
            if (dropDownRef.current !== null && !dropDownRef.current.contains(e.target)) {
                setIsDropDown(!isDropDown);
            }
        };
        if (isDropDown) {
            window.addEventListener('click', dropEffectClick);
        }
        return () => {
            window.removeEventListener('click', dropEffectClick);
        };
    }, [isDropDown]);

    //guuide shenanigans
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const steps = [
        {
            title: (
                <Stack direction="row" alignItems="center" gap={1}>
                    <img src="/img/dashboard-ai-white.svg" className="w-[36px]" alt="Dashboard AI icon" />
                    <Box>
                        <Typography sx={{ fontSize: 11, fontWeight: 600, color: theme.palette.neutral[30] }}>
                            Vifly
                        </Typography>
                        <Typography sx={{ fontSize: 10, fontWeight: 300, color: theme.palette.neutral[60] }}>
                            SmartComply AI
                        </Typography>
                    </Box>
                </Stack>
            ),
            description: (
                <>
                    <Typography sx={{ fontSize: 12, fontWeight: 600, color: theme.palette.neutral[50] }}>
                        Hello {merchant_info?.name} 👋
                    </Typography>
                    <Typography sx={{ fontSize: 12, fontWeight: 400, color: theme.palette.neutral[50] }}>
                        Compliance health indicates the areas that require your attention. The more attention you can
                        address, the greater your level of compliance.
                    </Typography>
                </>
            ),
            target: () => ref1.current,
            nextButtonProps: {
                children: 'Next',
                onClick: () => openSuggestionsModal(),
                style: { fontSize: '10px !important', width: 'fit-content !important' },
            },
        },
        {
            title: (
                <Stack direction="row" alignItems="center" gap={1}>
                    <img src="/img/dashboard-ai-white.svg" className="w-[36px]" alt="Dashboard AI icon" />
                    <Box>
                        <Typography sx={{ fontSize: 11, fontWeight: 600, color: theme.palette.neutral[30] }}>
                            Vifly
                        </Typography>
                        <Typography sx={{ fontSize: 10, fontWeight: 300, color: theme.palette.neutral[60] }}>
                            SmartComply AI
                        </Typography>
                    </Box>
                </Stack>
            ),
            description: (
                <>
                    <Typography sx={{ fontSize: 12, fontWeight: 600, color: theme.palette.neutral[50], mb: 2 }}>
                        Compliance health summary👋
                    </Typography>
                    <Typography sx={{ fontSize: 12, fontWeight: 400, color: theme.palette.neutral[50] }}>
                        Click on the <strong>Compliance health tab,</strong> to view a rundown of areas that need
                        attention.
                    </Typography>
                </>
            ),
            target: () => ref2.current,
            prevButtonProps: {
                children: 'Prev',
                onClick: () => closeSuggestionsModal(),
            },
            nextButtonProps: {
                children: 'Next',
                onClick: () => closeSuggestionsModal(),
                style: { fontSize: '10px !important', width: 'fit-content !important' },
            },
        },
        {
            title: (
                <Stack direction="row" alignItems="center" gap={1}>
                    <img src="/img/dashboard-ai-white.svg" className="w-[36px]" alt="Dashboard AI icon" />
                    <Box>
                        <Typography sx={{ fontSize: 11, fontWeight: 600, color: theme.palette.neutral[30] }}>
                            Vifly
                        </Typography>
                        <Typography sx={{ fontSize: 10, fontWeight: 300, color: theme.palette.neutral[60] }}>
                            SmartComply AI
                        </Typography>
                    </Box>
                </Stack>
            ),
            description: (
                <>
                    <Typography sx={{ fontSize: 12, fontWeight: 600, color: theme.palette.neutral[50], mb: 3 }}>
                        Need more help?
                    </Typography>
                    <Typography sx={{ fontSize: 12, fontWeight: 400, color: theme.palette.neutral[50] }}>
                        With Vifly, you can get some suggestions, ask questions relating to compliance and
                        cybersecurity.
                    </Typography>
                </>
            ),
            target: () => ref3.current,
            prevButtonProps: {
                children: 'Prev',
                onClick: () => openSuggestionsModal(),
            },
        },
    ];

    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    //UserGuide
    const OpenUserGuide = () => {
        setOpenTour(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ compliance_health: true });
        setToggleUserGuide({ ...toggleUserGuide, compliance_health: false });
        setOpenTour(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, dashboard: false });
        setOpenArticle(false);
    };

    useEffect(() => {
        !userguide_info && !toggleUserGuide?.compliance_health
            ? OpenUserGuide()
            : toggleUserGuide.dashboard
            ? OpenArticle()
            : null;
    }, [userguide_info, toggleUserGuide]);

    useEffect(() => {
        !userguide_info && !toggleUserGuide?.compliance_health
            ? OpenUserGuide()
            : toggleUserGuide.dashboard
            ? OpenArticle()
            : null;
    }, [userguide_info, toggleUserGuide]);

    // console.log({ dashboardResult });

    return (
        <div className="relative">
            <PageHeader browserTitle="Dashboard | Smartcomply" />
            {view === 'dashboard' ? (
                <>
                    <TopDashboardReport pricing_plan={pricing_plan} onChangeView={onChangeView} />
                    <div className="py-3 px-4 border-b border-gray-[#B8BCCC] bg-white flex items-center justify-between font-bold textbrandColor relative">
                        <button
                            className="absolute top-1/2 -translate-y-1/2 left-[0.5%] bg-[#F2F0F4] rounded-full grid place-items-center h-6 w-6 cursor-pointer disabled:opacity-25 disabled:pointer-events-none"
                            onClick={() => {
                                if (scrollContainerRef.current) {
                                    scrollContainerRef.current.scrollBy({
                                        left: -200,
                                        behavior: 'smooth',
                                    });
                                }
                            }}
                            disabled={isLeftDisabled}
                        >
                            <img
                                src="/img/automated-scan/arrow-left-icon.svg"
                                alt="Arrow Left Icon"
                                className="object-contain scale-75"
                            />
                        </button>

                        <button
                            className="absolute top-1/2 -translate-y-1/2 right-[9%] bg-[#F2F0F4] rounded-full grid place-items-center h-6 w-6 cursor-pointer disabled:opacity-25 disabled:pointer-events-none"
                            onClick={() => {
                                if (scrollContainerRef.current) {
                                    scrollContainerRef.current.scrollBy({
                                        left: 200,
                                        behavior: 'smooth',
                                    });
                                }
                            }}
                            disabled={isRightDisabled}
                        >
                            <img
                                src="/img/automated-scan/arrow-left-icon.svg"
                                alt="Arrow Right Icon"
                                className="object-contain rotate-180 scale-75"
                            />
                        </button>

                        <Stack
                            sx={{
                                backgroundColor: 'fff',
                                alignItems: 'center',
                                flexDirection: 'row',
                                color: theme.palette.primary[900],
                                flexBasis: '90%',
                                overflowX: 'auto',
                                overflowY: 'hidden',
                                fontWeight: 600,
                                gap: 4,
                                scrollbarWidth: 'none',
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'transparent',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: theme.palette.primary[900] + '30',
                                    borderRadius: '10px',
                                },
                            }}
                            className="dash_tab"
                            ref={scrollContainerRef}
                        >
                            <div
                                className={`flex items-center cursor-pointer px-3 transition tab ${
                                    indexValue === 1 && 'active'
                                }`}
                                onClick={() => onChangeTab(1)}
                            >
                                <span className="capitalize text-sm">Overview</span>
                            </div>
                            {pricing_plan?.tag !== 'free'
                                ? accessTab?.map((res, index) => (
                                      <React.Fragment key={index}>
                                          <div
                                              className={`flex items-center cursor-pointer px-2 transition tab ${
                                                  indexValue === res.id && 'active'
                                              }`}
                                              onClick={() => onChangeTab(res.id)}
                                          >
                                              <span className="uppercase text-sm whitespace-nowrap">
                                                  {res?.name === 'pcidss4.0' ? 'pcidss 4.0' : res?.name}
                                              </span>
                                              {res.image && (
                                                  <img className="ml-2" width={18} src={res.image} alt="Checkdone" />
                                              )}
                                          </div>
                                      </React.Fragment>
                                  ))
                                : freePlanTab?.map((res, index) => (
                                      <React.Fragment key={index}>
                                          <div
                                              className={`flex items-center cursor-pointer px-3 transition tab ${
                                                  indexValue === res.id && 'active'
                                              }`}
                                              onClick={() => onChangeTab(res.id)}
                                          >
                                              <span className="uppercase whitespace-nowrap">{res.name}</span>
                                              {res.image && (
                                                  <img className="ml-2" width={18} src={res.image} alt="Checkdone" />
                                              )}
                                          </div>
                                      </React.Fragment>
                                  ))}
                        </Stack>
                        <div
                            ref={dropDownRef}
                            className="w-[30px] h-[26.5px] bg-[#F2F0F4] flex items-center cursor-pointer justify-center rounded-[4px]"
                            onClick={() => setIsDropDown(!isDropDown)}
                        >
                            <HiDotsHorizontal />
                        </div>

                        <div
                            className={`${
                                isDropDown ? 'block' : 'hidden'
                            } bg-white shadow-md rounded-md px-3 w-fit absolute py-3 transition right-1 z-10 mt-2`}
                        >
                            {pricing_plan?.tag !== 'free' && (
                                <div className="flex items-center mb-2 cursor-pointer">
                                    <img src={ReportIcon} alt="ReportIcon" />
                                    <span className="text-[#46464A] pl-2 text-[13px]" onClick={onChangeView}>
                                        {t('spool')}
                                    </span>
                                </div>
                            )}
                            <div className="flex items-center mb-2 cursor-pointer" onClick={gotoPlanSettings}>
                                <img src={UpgradeIcon} alt="UpgradeIcon" />
                                <span className="text-[#46464A] pl-2 text-[13px]">{t('upgrade')}</span>
                            </div>
                            {isFullFunctionPlan(pricing_plan?.tag) && (
                                <div className="flex items-center cursor-pointer" onClick={openAuditModal}>
                                    <img src={DocumentIcon} alt="ReportIcon" />
                                    <span className="text-[#46464A] pl-2 text-[13px]">{t('requestAuditor')}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    {indexValue === 1 && (
                        <div>
                            <SecondLayerReport
                                pricing_plan={pricing_plan}
                                dashboardResult={dashboardResult}
                                standards={standards}
                                onChangeView={onChangeView}
                                openSuggestionsModal={openSuggestionsModal}
                                suggestionsModalOpen={suggestionsModalOpen}
                                closeSuggestionsModal={closeSuggestionsModal}
                                smartComplianceReport={smartComplianceReport}
                                ai_recommendations={ai_recommendations}
                                merchant_info={merchant_info}
                                ref1={ref1}
                                ref2={ref2}
                            />
                            <ThirdLayerReport pricing_plan={pricing_plan} dashboardResult={dashboardResult} />
                            <FourthLayerReport pricing_plan={pricing_plan} dashboardResult={dashboardResult} />
                        </div>
                    )}
                    {indexValue === 2 && (
                        <div>
                            <NDPRIndex
                                percentage={dashboardResult?.ndpr_percentage}
                                ndprSummary={ndprSummary}
                                ndprGeneral={ndprGeneral}
                            />
                        </div>
                    )}
                    {indexValue === 3 && (
                        <div>
                            <SOCIndex
                                percentage={dashboardResult?.soc2_percentage}
                                socGeneral={socGeneral}
                                socSummary={socSummary}
                            />
                        </div>
                    )}
                    {indexValue === 4 && (
                        <div>
                            <PCIDSSIndex
                                percentage={dashboardResult?.pci_percentage}
                                pciGeneral={pciGeneral}
                                pciSummary={pciSummary}
                            />
                        </div>
                    )}
                    {indexValue === 5 && (
                        <div>
                            <ISOIndex
                                percentage={dashboardResult?.iso_percentage}
                                isoGeneral={isoGeneral}
                                isoSummary={isoSummary}
                            />
                        </div>
                    )}

                    {indexValue === 6 && (
                        <div>
                            <BLOCKCHAINIndex
                                percentage={dashboardResult?.blockchain_percentage}
                                all_criteria_summary={all_criteria_summary}
                            />
                        </div>
                    )}
                    {indexValue === 7 && (
                        <div>
                            <CONTINUITYIndex
                                percentage={dashboardResult?.continuity_percentage}
                                continuityGeneral={continuityGeneral}
                                continuitySummary={continuitySummary}
                            />
                        </div>
                    )}
                    {indexValue === 8 && (
                        <div>
                            <PCIDSS4Point0Index
                                percentage={dashboardResult?.card4_percentage}
                                pcidss4Point0General={pcidss4Point0General}
                                pcidss4Point0Summary={pcidss4Point0Summary}
                            />
                        </div>
                    )}
                    {/* {indexValue === 11 && (
                        <div>
                            <Cii percentage={dashboardResult?.cii_percentage} ciiGeneral={ciiGeneral} />
                        </div>
                    )} */}
                    {indexValue === 9 && (
                        <div>
                            <KDPAIndex
                                percentage={dashboardResult?.kdpa_percentage}
                                kdpaSummary={kdpaSummary}
                                kdpaGeneral={kdpaGeneral}
                            />
                        </div>
                    )}
                    {indexValue === 10 && (
                        <div>
                            <GDPAIndex
                                percentage={dashboardResult?.gdpa_percentage}
                                gdpaGeneral={gdpaGeneral}
                                gdpaSummary={gdpaSummary}
                            />
                        </div>
                    )}
                    {indexValue === 11 && (
                        <div>
                            <Cii percentage={dashboardResult?.cii_percentage} ciiGeneral={ciiGeneral} />
                        </div>
                    )}
                    {indexValue === 12 && (
                        <div>
                            <ISOTWOIndex
                                percentage={dashboardResult?.iso_two_percentage}
                                isoGeneral={isotwoGeneral}
                                isoSummary={isotwoSummary}
                            />
                        </div>
                    )}
                    {indexValue === 13 && (
                        <div>
                            <ISO27001plusIndex
                                percentage={dashboardResult?.techsec_percentage}
                                techSecGeneral={techSecGeneral}
                                techSecSummary={techSecSummary}
                            />
                        </div>
                    )}
                    {indexValue === 14 && (
                        <div>
                            <PCISLC
                                percentage={dashboardResult?.sslc_percentage}
                                pcislc_general_summary={pcislc_general_summary}
                                pcislc_summary_by_control={pcislc_summary_by_control}
                            />
                        </div>
                    )}
                    {indexValue === 15 && (
                        <div>
                            <ISO9001
                                percentage={dashboardResult?.iso9001_percentage}
                                iso9001_general_summary={iso9001_general_summary}
                                iso9001_summary_by_control={iso9001_summary_by_control}
                            />
                        </div>
                    )}
                </>
            ) : (
                <>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" alignItems="center">
                            <Box
                                onClick={() => setView('dashboard')}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '50%',
                                    width: '45px',
                                    height: '45px',
                                    padding: '7px',
                                    backgroundColor: theme.palette.primary[300] + '30',

                                    cursor: 'pointer',
                                }}
                            >
                                <KeyboardArrowLeft />
                            </Box>
                            <Typography sx={{ ml: 2, color: theme.palette.primary[900], fontWeight: 700 }}>
                                Go to Dashboard
                            </Typography>
                        </Stack>
                        <AppButton
                            name="Download Report"
                            variant="contained"
                            color="primary"
                            onClick={() => pdfWithPrintJs('ciso-report', 'ciso report')}
                        />
                    </Stack>
                    <div id="ciso-report" className="p-2 mt-6">
                        <CisoReport standards={standards} />
                    </div>
                </>
            )}
            {diffrenceResult === 1 && all_survey?.length > 0 && all_survey?.length !== survey_responses?.length ? (
                <Button
                    variant={'contained'}
                    startIcon={<PollIcon />}
                    onClick={handleOpenSummary}
                    size="large"
                    sx={{
                        transform: 'rotate(-90deg)',
                        textTransform: 'capitalize',
                        borderRadius: '14px 14px 0px 0px',
                        position: 'absolute',
                        top: '30%',
                        right: '-5.5%',
                        overflowX: 'hidden !important',
                    }}
                >
                    Survey
                </Button>
            ) : diffrenceResult > 1 && all_survey?.length > 0 && all_survey?.length !== survey_responses?.length ? (
                // <B
                <Button
                    variant={'contained'}
                    startIcon={<img src={SurveyIcon} alt="SurveyIcon" style={{ transform: 'rotate(-270deg)' }} />}
                    onClick={handleOpenSummary}
                    size="large"
                    sx={{
                        transform: 'rotate(-90deg)',
                        textTransform: 'capitalize',
                        borderRadius: '14px 14px 0px 0px',
                        position: 'absolute',
                        top: '30%',
                        right: '-5.5%',
                        background: 'linear-gradient(300.37deg, #0E2C66 -17.55%, rgba(23, 65, 188, 0.83) 94.02%)',
                        overflowX: 'hidden !important',
                    }}
                >
                    Survey
                </Button>
            ) : null}
            {openSummary && <Summary open={openSummary} onClose={handleCloseSummary} loading={loading} />}
            {aIModalOpen ? (
                <IconButton
                    sx={{
                        position: 'fixed',
                        bottom: '2%',
                        right: '3%',
                        backgroundColor: 'white !important',
                        borderRadius: '100px',
                        boxShadow: '0px 4px 8px 0px rgba(152, 152, 152, 0.04)',
                    }}
                    onClick={closeAIModal}
                >
                    <img src="/img/more.svg" className="w-[50px]" alt="Dashboard AI icon" />
                </IconButton>
            ) : (
                <IconButton
                    ref={ref3}
                    sx={{
                        position: 'fixed',
                        bottom: '2%',
                        right: '3%',
                        backgroundColor: 'transparent !important',
                        '&:hover': {
                            backgroundColor: 'transparent !important',
                        },
                    }}
                    onClick={openAIModal}
                >
                    <img src="/img/dashboard-ai.svg" className="w-[80px]" alt="Dashboard AI icon" />
                </IconButton>
            )}
            <InHouseAuditModal isOpen={isOpen} onClose={onClose} />
            <SmartDashboardAI open={aIModalOpen} handleClose={closeAIModal} />
            <Tour open={openTour} mask={true} onClose={CloseUserGuide} steps={steps} />
            <AppArticle open={openArticle} handleClose={CloseArticle} title="User Guide" icon={true} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        dashboardResult: state?.dashboardReducers?.dashboardResult,
        ndprSummary: state?.dashboardReducers?.ndprSummary,
        ndprGeneral: state?.dashboardReducers?.ndprGeneral,
        gdpaSummary: state?.dashboardReducers?.gdpaSummary,
        gdpaGeneral: state?.dashboardReducers?.gdpaGeneral,
        kdpaSummary: state?.dashboardReducers?.kdpaSummary,
        kdpaGeneral: state?.dashboardReducers?.kdpaGeneral,
        socGeneral: state?.dashboardReducers?.socGeneral,
        socSummary: state?.dashboardReducers?.socSummary,
        pciGeneral: state?.dashboardReducers?.pciGeneral,
        pciSummary: state?.dashboardReducers?.pciSummary,
        isoGeneral: state?.dashboardReducers?.isoGeneral,
        isotwoGeneral: state?.dashboardReducers?.isotwoGeneral,
        ciiGeneral: state?.dashboardReducers?.cii_general_summary,
        isoSummary: state?.dashboardReducers?.isoSummary,
        isotwoSummary: state?.dashboardReducers?.isotwoSummary,
        pcidss4Point0General: state?.dashboardReducers?.pcidss4Point0General,
        pcidss4Point0Summary: state?.dashboardReducers?.pcidss4Point0Summary,
        pricing_plan: state?.authReducers.pricing_plan,
        organization: state?.generalReducers?.user_info?.organization,
        merchant_info: state?.merchantReducers?.merchant_info,
        all_criteria_summary: state?.complianceReducers?.all_criteria_summary,
        all_survey: state?.adminReducers?.all_survey,
        continuitySummary: state?.complianceReducers?.all_continuitygroups_metrics,
        continuityGeneral: state?.dashboardReducers?.continuityGeneral,
        survey_responses: state?.merchantReducers?.survey_responses,
        smartComplianceReport: state?.dashboardReducers?.smartComplianceReport,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.compliance_health,
        ai_recommendations: state?.dashboardReducers?.smart_compliance_ai_recommendations,
        techSecGeneral: state?.dashboardReducers?.techSecGeneral,
        techSecSummary: state?.dashboardReducers?.techSecSummary,
        pcislc_general_summary: state.dashboardReducers.pcislc_general_summary,
        pcislc_summary_by_control: state.dashboardReducers.pcislc_summary_by_control,
        iso9001_general_summary: state.dashboardReducers.iso9001_general_summary,
        iso9001_summary_by_control: state.dashboardReducers.iso9001_summary_by_control,
    };
};
export default connect(mapStateToProps, {
    GetDashboardCompliance,
    GetMerchantNDPRSummaryByCategory,
    GetMerchantKDPASummaryByCategory,
    GetMerchantGDPASummaryByCategory,
    GetMerchantNDPRGeneralSummary,
    GetMerchantKDPAGeneralSummary,
    GetMerchantGDPAGeneralSummary,
    GetMerchantSoc2GeneralSummary,
    GetMerchantSoc2SummaryByControl,
    GetMerchantOverviewSummary,
    GetMerchantComplianceSummary,
    GetMerchantIsoGeneralSummary,
    GetMerchantIsoTwoGeneralSummary,
    GetMerchantContinuityGeneralSummary,
    GetMerchantIsoSummaryByAnnex,
    GetMerchantIsoTwoSummaryByAnnex,
    GetMerchantPcidss4point0OverviewSummary,
    GetMerchantPcidss4point0ComplianceSummary,
    GetSmartComplianceReport,
    GetCompanyDetails,
    GetMerchantCiiGeneralSummary,
    GetCriteriaSummaries,
    GetCiiSectorSummaries,
    GetContinuityGroupMetrics,
    GetAllSurvey,
    GetAllSurveyResponse,
    // GetSmartComplianceAIRecommendations,
    GetMerchantPCISLCGeneralSummary,
    GetMerchantPCISLCSummaryByControl,
    GetMerchantISO9901CGeneralSummary,
    GetMerchantISO9001SummaryByManagement,
})(DashboardIndex);
