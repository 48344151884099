import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

//redux
import { connect, useDispatch, useSelector } from 'react-redux';
import { GetAllTasksByStandard } from 'store/actions/taskActions';

//core components
import AppViewModal from 'components/new_components/AppViewModal';
import { UpdateTask } from 'store/actions/taskActions';
import SouthIcon from '@mui/icons-material/South';
import EastIcon from '@mui/icons-material/East';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { toast } from 'react-toastify';
import LoadingState from 'components/new_components/LoadingState';
import { GetEvidencesByControl, GetEvidencesBySubcontrol } from 'store/actions/adminActions';
import Evidences from './Evidences';
import EvidenceExceptions from './exceptions_new/Exceptions';
import UploadEvidenceModal from './UploadEvidenceModal';
import EvidenceHistoryModal from './EvidenceHistoryModal';
import ExemptEvidenceModal from './ExemptEvidenceModal';
import { GetEvidencesByCard4 } from 'store/actions/pcidssActions';
import { GetEvidenceExeption } from 'store/actions/complianceActions';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';

const DataRoomModal = (props) => {
    //props
    const {
        open,
        handleClose,
        all_requirements,
        // GetEvidencesByControl,
        standard,
        all_evidences_metrics,
        evidence_type,
        all_merchant_evidences,
    } = props;
    // state
    const [type, setType] = useState('evidences');
    const [segment, setSegment] = useState('');
    const [segmentNumber, setSegmentNumber] = useState();
    const [active, setActive] = useState();
    const [doc_id, setDoc_id] = useState();
    const [control, setControl] = useState();
    const [loading, setLoading] = useState(false);
    const [evidenceHistoryModal, setEvidenceHistoryModal] = useState(false);
    const [uploadEvidenceModal, setUploadEvidenceModal] = useState(false);
    const [exemptEvidenceModal, setExemptEvidenceModal] = useState(false);
    const [evidence, setEvidence] = useState(null);
    const [evidence_, setEvidence_] = useState(null);
    const dispatch = useDispatch();
    const { activeMerchant } = useContext(SelectedMerchantContext);

    const all_evidence_by_card4 = useSelector((state) => state?.pcidssReducers?.all_evidence_by_card4);
    const all_exempted_evidences = useSelector((state) => state?.complianceReducers?.all_exempted_evidences);

    const evidences_by_control = all_evidence_by_card4;

    const onClose = () => {
        setActive();
        handleClose();
    };

    // mui
    const theme = useTheme();

    const openExemptEvidenceModal = (control) => {
        setEvidence_(control);
        setExemptEvidenceModal(true);
    };

    const closeExemptEvidenceModal = () => {
        setEvidence_(null);
        setExemptEvidenceModal(false);
    };

    const openEvidenceHistoryModal = () => {
        setEvidenceHistoryModal(true);
    };

    const closeEvidenceHistoryModal = () => {
        setEvidenceHistoryModal(false);
    };

    const openEvidenceModal = (item, number) => {
        setSegment(item);
        setSegmentNumber(number);
        setUploadEvidenceModal(true);
    };

    const closeEvidenceModal = () => {
        setUploadEvidenceModal(false);
    };

    const getControlObjectiveEvidences = async (subcontrolId) => {
        if (active === subcontrolId) {
            setActive('');
        } else {
            setActive(subcontrolId);
        }
        setLoading(true);
        const res = await dispatch(GetEvidencesByCard4(subcontrolId));
        setLoading(false);
        if (!res?.success) {
            toast.error('Something went wrong!');
        }
    };

    // async functions
    const getAllExemptedEvidence = async () => {
        setLoading(true);
        const res = await dispatch(GetEvidenceExeption('card4', activeMerchant));
        setLoading(false);
        if (!res?.success) {
            toast.error("Something went wrong! Couldn't fetch exceptions.");
        }
    };

    useEffect(() => {
        getAllExemptedEvidence();
    }, [activeMerchant]);

    const selectedEvidence = useMemo(() => {
        return all_merchant_evidences?.find((doc) => doc?.evidence_id === doc_id);
    }, [doc_id, all_merchant_evidences]);

    const filtered_exceptions = all_exempted_evidences?.map((option) => option?.evidence_detail?.card4);
    const filtered_requirements = all_requirements?.filter((item) => filtered_exceptions?.includes(item?.id));
    console.log({ all_evidences_metrics });
    return (
        <AppViewModal sx={{ overflowX: 'hidden' }} open={open} handleClose={onClose} width={520}>
            <Box sx={{ my: 1.5 }}>
                <Box sx={{ display: 'flex', mx: 3, pb: 2, alignItems: 'center' }}>
                    <ArrowBackIosIcon
                        onClick={onClose}
                        sx={{ width: 20, height: 20, color: '#000000', cursor: 'pointer' }}
                    />
                    <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#202D66', ml: 2 }}>
                        Additional Evidences
                    </Typography>
                </Box>
                <Divider sx={{ color: theme.palette.neutral[95] }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mx: '7rem', mt: 4 }}>
                    <Box>
                        <Typography
                            sx={{
                                color: type === 'evidences' ? '#202D66' : '#94A3B8',
                                fontSize: '14px',
                                fontWeight: 500,
                                textAlign: 'right',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setActive();
                                setType('evidences');
                            }}
                        >
                            Evidences
                        </Typography>
                        {type === 'evidences' && (
                            <Box
                                sx={{
                                    backgroundColor: '#202D66',
                                    width: '100%',
                                    pt: 0.5,
                                    borderTopLeftRadius: '10px',
                                    borderTopRightRadius: '10px',
                                }}
                            ></Box>
                        )}
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                color: type === 'exceptions' ? '#202D66' : '#94A3B8',
                                fontSize: '14px',
                                fontWeight: 500,
                                textAlign: 'left',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setActive();
                                setType('exceptions');
                            }}
                        >
                            Evidence Exceptions
                        </Typography>
                        {type === 'exceptions' && (
                            <Box
                                sx={{
                                    backgroundColor: '#202D66',
                                    width: '100%',
                                    pt: 0.5,
                                    borderTopLeftRadius: '10px',
                                    borderTopRightRadius: '10px',
                                }}
                            ></Box>
                        )}
                    </Box>
                </Box>
                <Divider sx={{ mx: '-3.5rem' }} />
                <Box sx={{ mt: 4 }}>
                    {type === 'evidences' ? (
                        <Box>
                            {all_requirements?.map((requirement, index) => {
                                const { total_evidences, user_evidences } =
                                    all_evidences_metrics?.[requirement?.requirement_title] || {};
                                const metric = { totalValue: total_evidences, totalAttendedTo: user_evidences };
                                return (
                                    <Box key={index}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                px: 2,
                                                backgroundColor: active === requirement?.id ? '#F1F5F9' : '',
                                                borderTop: '1px solid #F1F5F9',
                                                borderBottom: '1px solid #F1F5F9',
                                                mb: 2,
                                                py: 1,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                getControlObjectiveEvidences(requirement?.id);
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {active === requirement?.id ? (
                                                    <Box
                                                        sx={{
                                                            backgroundColor: '#F8FAFC',
                                                            py: 0.3,
                                                            px: 0.7,
                                                            borderRadius: 3,
                                                            border: '1px solid #F1F5F9',
                                                            boxShadow: '2px 2px 8px 0px #09090914',
                                                        }}
                                                    >
                                                        <SouthIcon
                                                            sx={{
                                                                color: '#AAABB4',
                                                                width: '20px',
                                                            }}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        sx={{
                                                            backgroundColor: '#F8FAFC',
                                                            py: 0.3,
                                                            px: 0.7,
                                                            borderRadius: 3,
                                                            border: '1px solid #F1F5F9',
                                                            boxShadow: '2px 2px 8px 0px #09090914',
                                                        }}
                                                    >
                                                        <EastIcon
                                                            sx={{
                                                                color: '#AAABB4',
                                                                width: '20px',
                                                            }}
                                                        />
                                                    </Box>
                                                )}
                                                <Typography
                                                    sx={{ color: '#475569', fontSize: '15px', fontWeight: 600, ml: 2 }}
                                                >
                                                    Requirement {requirement?.requirement_number}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <FiberManualRecordIcon
                                                    sx={{
                                                        width: '6px',
                                                        height: '6px',
                                                        color:
                                                            metric?.totalAttendedTo === 0 && metric?.totalValue > 0
                                                                ? ''
                                                                : metric?.totalAttendedTo > 0 &&
                                                                  metric?.totalAttendedTo < metric?.totalValue
                                                                ? '#D2A500'
                                                                : '#55BE8B',
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        fontSize: '12px',
                                                        fontWeight: 600,
                                                        color: metric?.totalAttendedTo === 0 ? '#77777A' : '#64748B',
                                                        ml: 0.5,
                                                    }}
                                                >
                                                    {metric?.totalAttendedTo > 0 &&
                                                    metric?.totalAttendedTo < metric?.totalValue
                                                        ? 'Incomplete Uploads'
                                                        : metric?.totalAttendedTo === 0 && metric?.totalValue > 0
                                                        ? 'No uploads yet'
                                                        : 'Completed'}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        {active === requirement?.id && (
                                            <Box sx={{ ml: 4, mr: 2, mb: 2 }}>
                                                {loading ? (
                                                    <LoadingState />
                                                ) : (
                                                    <>
                                                        {evidences_by_control?.length > 0 ? (
                                                            <Box>
                                                                {evidences_by_control.map((control) => (
                                                                    <Evidences
                                                                        key={control?.id}
                                                                        control={control}
                                                                        setDoc_id={setDoc_id}
                                                                        openEvidenceHistoryModal={
                                                                            openEvidenceHistoryModal
                                                                        }
                                                                        closeEvidenceHistoryModal={
                                                                            closeEvidenceHistoryModal
                                                                        }
                                                                        openExemptEvidenceModal={
                                                                            openExemptEvidenceModal
                                                                        }
                                                                        evidenceHistoryModal={evidenceHistoryModal}
                                                                        standard={standard}
                                                                        doc_id={doc_id}
                                                                        req_id={requirement}
                                                                        setControl={setControl}
                                                                        evidence_type={evidence_type}
                                                                        selectedEvidence={selectedEvidence}
                                                                        setControlModal={setEvidence}
                                                                    />
                                                                ))}
                                                            </Box>
                                                        ) : (
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    color: '#475569',
                                                                    fontSize: '15px',
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                <Typography>No Evidence Required</Typography>
                                                            </Box>
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                );
                            })}
                        </Box>
                    ) : (
                        <Box>
                            {filtered_requirements?.length > 0 ? (
                                <Box>
                                    {filtered_requirements?.map((requirement, index) => {
                                        return (
                                            <Box key={index}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        px: 2,
                                                        backgroundColor: active === requirement?.id ? '#F1F5F9' : '',
                                                        borderTop: '1px solid #F1F5F9',
                                                        borderBottom: '1px solid #F1F5F9',
                                                        mb: 2,
                                                        py: 1,
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        getControlObjectiveEvidences(requirement?.id);
                                                    }}
                                                >
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        {active === requirement?.id ? (
                                                            <Box
                                                                sx={{
                                                                    backgroundColor: '#F8FAFC',
                                                                    py: 0.3,
                                                                    px: 0.7,
                                                                    borderRadius: 3,
                                                                    border: '1px solid #F1F5F9',
                                                                    boxShadow: '2px 2px 8px 0px #09090914',
                                                                }}
                                                            >
                                                                <SouthIcon
                                                                    sx={{
                                                                        color: '#AAABB4',
                                                                        width: '20px',
                                                                    }}
                                                                />
                                                            </Box>
                                                        ) : (
                                                            <Box
                                                                sx={{
                                                                    backgroundColor: '#F8FAFC',
                                                                    py: 0.3,
                                                                    px: 0.7,
                                                                    borderRadius: 3,
                                                                    border: '1px solid #F1F5F9',
                                                                    boxShadow: '2px 2px 8px 0px #09090914',
                                                                }}
                                                            >
                                                                <EastIcon
                                                                    sx={{
                                                                        color: '#AAABB4',
                                                                        width: '20px',
                                                                    }}
                                                                />
                                                            </Box>
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                color: '#475569',
                                                                fontSize: '15px',
                                                                fontWeight: 600,
                                                                ml: 2,
                                                            }}
                                                        >
                                                            Requirement {requirement?.requirement_number}
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                {active === requirement?.id && (
                                                    <Box sx={{ ml: 4, mr: 2, mb: 2 }}>
                                                        <EvidenceExceptions
                                                            standard={standard}
                                                            doc_id={doc_id}
                                                            requirement={requirement}
                                                        />
                                                    </Box>
                                                )}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#475569',
                                        fontSize: '15px',
                                        fontWeight: 600,
                                    }}
                                >
                                    <Typography>No Evidence Exempted</Typography>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
            <UploadEvidenceModal
                open={uploadEvidenceModal}
                handleClose={closeEvidenceModal}
                doc_id={doc_id}
                standard={standard}
                control={control?.id}
                evidence_type={evidence_type}
                segment={segment}
                segmentNumber={segmentNumber}
            />

            <EvidenceHistoryModal
                open={evidenceHistoryModal}
                handleClose={closeEvidenceHistoryModal}
                selected={selectedEvidence}
                doc_id={doc_id}
                evidence_type={evidence_type}
                evidence={evidence}
                openEvidenceModal={openEvidenceModal}
                control={control?.id}
                standard={standard}
            />
            <ExemptEvidenceModal
                open={exemptEvidenceModal}
                handleClose={closeExemptEvidenceModal}
                evidence={evidence_}
                standard={standard}
            />
        </AppViewModal>
    );
};

const mapStateToProps = (state) => {
    return {
        all_assigned_task: state?.tasksReducers?.all_assigned_tasks_by_standard,
        user_id: state?.authReducers?.user_info?.employee?.id,
        merchant_documents: state?.complianceReducers?.all_merchant_documents,
        all_merchant_documents: state?.complianceReducers?.all_merchant_documents,
        all_merchant_evidences: state?.complianceReducers?.all_merchant_evidences,
    };
};
export default connect(mapStateToProps, {
    GetAllTasksByStandard,
    UpdateTask,
    GetEvidencesByControl,
    GetEvidencesBySubcontrol,
})(DataRoomModal);
