import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

// core components
import AuthViewContainer from './components/AuthViewContainer';
import PageHeader from 'components/new_components/PageHeader';

// redux
import { connect, useDispatch, useSelector } from 'react-redux';
import { ConfirmOtp } from 'store/actions/authActions';
import { toast } from 'react-toastify';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import AppOptInput from 'components/new_components/AppOptInput';
import { getDomain } from 'utils';
import LightIcon from 'assets/img/ColouredLightBulb.svg';
import { UpdateUserDetails } from 'store/actions/generalActions';

const OtpScreen = (props) => {
    const { ConfirmOtp, company_details } = props;
    const location = useLocation();
    const history = useHistory();
    const theme = useTheme();
    const dispatch = useDispatch();

    // state
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState(new Array(6).fill(undefined));
    const user_details = useSelector((state) => state?.generalReducers?.user_info);
    // const onLoad = localStorage.getItem('onLoad');

    // memo
    const error = useMemo(() => location?.state?.error, []);
    const updateUser = async () => {
        const payload = {
            mfa_popup: user_details?.mfa_popup + 1,
        };
        const response = await dispatch(UpdateUserDetails(payload));
        setLoading({ ...loading, submit: false });
        if (response?.success) {
            if (company_details?.enterprise) {
                const domain_name = getDomain('domain');
                const route = `https://${company_details?.enterprise_name}.${domain_name}/merchant/index`;
                window.location.assign(route);
            } else {
                setTimeout(() => {
                    history.push(
                        location?.state?.user_type === 'merchant' || location?.state?.user_type === 'employee'
                            ? '/merchant/index'
                            : location?.state?.user_type === 'admin' ||
                              location?.state?.user_type === 'aud_admin' ||
                              location?.state?.user_type === 'aud_admin'
                            ? '/admin'
                            : location?.state?.user_type === 'auditor'
                            ? '/auditor'
                            : '/merchant/index'
                    );
                }, 3000);
                setTimeout(() => setOtp(''), 3000);
            }
        } else {
            toast.error(response?.message);
        }
    };

    // functions
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const res = await ConfirmOtp({ otp: otp.join(''), email: location?.state?.email });
        setTimeout(() => setLoading(false), 2000);
        if (res?.success) {
            if (user_details?.mfa_popup < 3) {
                updateUser();
            } else {
                if (company_details?.enterprise) {
                    const domain_name = getDomain('domain');
                    const route = `https://${company_details?.enterprise_name}.${domain_name}/merchant/index`;
                    window.location.assign(route);
                } else {
                    setTimeout(() => {
                        window.location.assign(
                            location?.state?.user_type === 'merchant' || location?.state?.user_type === 'employee'
                                ? '/merchant/index'
                                : location?.state?.user_type === 'admin' ||
                                  location?.state?.user_type === 'aud_admin' ||
                                  location?.state?.user_type === 'aud_admin'
                                ? '/admin'
                                : location?.state?.user_type === 'auditor'
                                ? '/auditor'
                                : '/merchant/index'
                        );
                    }, 3000);
                    setTimeout(() => setOtp(''), 3000);
                }
            }
        } else {
            toast.error(res?.message);
        }
    };

    const handleOtpChange = (otp) => {
        setOtp(otp);
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('Text').trim()?.toString()?.slice(0, 6);
        const pinArray = pastedData?.split('').length > 0 ? pastedData?.split('') : Array(6).fill(undefined);
        setOtp(pinArray);
    };

    // useEffects
    useEffect(() => {
        if (history.action === 'REPLACE') {
            toast.info(error);
        }
    }, [error]);

    return (
        <div>
            <PageHeader browserTitle="2FA Authentication | Smartcomply" />
            <AuthViewContainer
                title="2-Factor Authentication"
                // subtitle={
                //     location?.state?.mfa_type === 'email'
                //         ? 'A verification code has been sent to your email.'
                //         : 'Check your authenticator app for your otp code.'
                // }
                subtitle={
                    user_details?.mfa_popup < 3 ? (
                        <Box
                            sx={{
                                backgroundColor: '#F8FAFC',
                                display: 'flex',
                                alignItems: 'flex-start',
                                my: 1.5,
                                py: 1,
                                px: 2,
                                borderRadius: 1,
                            }}
                        >
                            <img src={LightIcon} alt={'LightIcon'} />
                            <Typography sx={{ color: '#395BA9', fontWeight: 400, ml: 1, fontSize: '14px' }}>
                                You can now{' '}
                                <Typography
                                    component="span"
                                    sx={{ color: '#395BA9', fontWeight: 600, fontSize: '13px' }}
                                >
                                    copy and paste
                                </Typography>{' '}
                                your 2-factor authentication code from your{' '}
                                {location?.state?.mfa_type === 'email' ? 'email' : 'authenticator app'}.
                            </Typography>
                        </Box>
                    ) : (
                        <Box>
                            {location?.state?.mfa_type === 'email'
                                ? 'A verification code has been sent to your email.'
                                : 'Check your authenticator app for your otp code.'}
                        </Box>
                    )
                }
                altLink={{ text: 'create account', to: '/auth/add-merchant' }}
                type={true}
                sx={{ width: { xs: '100%', md: '50%' }, mx: 'auto' }}
            >
                <form onSubmit={handleSubmit}>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: '14px',
                            color: theme.palette.gray[600],
                            mb: 1,
                        }}
                    >
                        Verification code
                    </Typography>
                    <AppOptInput pinArray={otp} onChange={handleOtpChange} handlePaste={handlePaste} />
                    <AppLoadingButton
                        text="Verify"
                        type="submit"
                        loading={loading}
                        variant="contained"
                        color="primary"
                        loadingPosition="center"
                        sx={{ mt: 3, width: '100%', py: 2 }}
                    />
                </form>
            </AuthViewContainer>
        </div>
    );
};

const mapStateToProps = (state) => {
    return { company_details: state?.authReducers?.company_details };
};

export default connect(mapStateToProps, { ConfirmOtp })(OtpScreen);
